/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './theme/mixins';

@import './theme/variables';

@import './theme/variables.epi';

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.mobile {
	display: none !important;
	@include mobile {
		display: block !important;
	}
}

.desktop {
	display: none !important;
	@include desktop {
		display: block !important;
	}
}

ion-label {
	font-family: AppFont, san-serif;
	font-size: 11px;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #808080;

	&.input {
		margin-top: 8px;
		margin-bottom: 2px;
	}
}

ion-label.input-error {
	color: red !important;
}

ion-label.selected {
	font-family: AppFont, sans-serif;
}

.stacked {
	position: relative;
	top: 10px;
	left: 18px;
	z-index: 2;
}

ion-item {
	--padding-start: 18px;
	--min-height: 45px;
	--border-radius: 0px;
	--background: white;
	--inner-border-width: 0px;
	border: 1px solid #ebe4de80;
	margin-bottom: 2px;

	font-family: AppFontLight;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #000000;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.info-icon {
	position: absolute;
	right: 3px;
	margin: 0;
	bottom: 7px;
	width: 14px;
}

.disabled {
	ion-input {
		color: #b5b5b5 !important;
	}

	.lock-icon {
		bottom: 5px;
		position: absolute;
		right: 0;
		width: 10px;
		height: 13px;
	}
}

.input-error {
	--color: red;
	--placeholder-color: red;
	margin-bottom: 0 !important;
}

.error-text {
	margin: 4px 0;
	width: 100%;
	color: red;
	font-size: x-small;
}

.terms {
	color: var(--ion-color-black);
	font-size: 14px;
	line-height: 21px;
	font-weight: bold;

	.underline {
		font-weight: bold;
		text-decoration: underline;
		margin: 0;
	}
	ion-button {
		z-index: 2;
	}
}

.no-text-transform {
	text-transform: none !important;
}

.text-small {
	font-size: small;
}

.text-medium {
	font-size: medium;
}

.text-large {
	font-size: large;
}

.text-x-large {
	font-size: x-large;
}

.text-xx-large {
	font-size: xx-large;
}

.place-items-center {
	place-items: center !important;
}

.white {
	fill: white;
	color: white;
}

.font-10 {
	font-size: 10px;
}

.font-12 {
	font-size: 12px;
}

.font-13 {
	font-size: 13px;
}

.font-14 {
	font-size: 14px;
}

.font-16 {
	font-size: 16px;
}

.font-18 {
	font-size: 18px;
}

.black {
	fill: black;
	color: black;
}

.flex-1 {
	flex: 1;
}

.relative {
	position: relative;
}

.grey {
	fill: #878787;
	color: #878787;
}

.dark {
	fill: var(--ion-color-black);
	color: var(--ion-color-black);
}

app-venue-suggestion {
	display: block;
}

app-account {
	ion-header {
		background: var(--ion-color-primary);
	}
}

.main-color {
	fill: var(--ion-color-primary);
	color: var(--ion-color-primary);
}

.second-color {
	fill: var(--ion-color-secondary);
	color: var(--ion-color-secondary);
}

.gray {
	fill: var(--ion-color-gray-darkest);
	color: var(--ion-color-gray-darkest);
}

.invisible {
	opacity: 0;
}

.flex {
	display: flex;
}

ion-footer::before {
	display: none;
}

ion-button,
.add-to-order-button {
	cursor: pointer;
	--background: var(--ion-color-primary);
	--background-activated: var(--ion-color-primary-shade);
	--background-hover: var(--ion-color-primary-shade);
	--riple-color: var(--ion-color-primary);
	--background-focused: var(--ion-color-primary-shade);
	color: var(--ion-color-primary-contrast);
	--color: var(--buttons-text-color);
	min-height: 54px;
	font-family: var(--buttons-font-family);
	text-align: center;
	--border-radius: var(--buttons-border-radius);
	--box-shadow: none;
	font-size: var(--buttons-font-size);
	margin: 0;
	text-transform: var(--buttons-text-transform);
	color: var(--buttons-text-color);
	&.button-small {
		font-size: small;
		min-height: auto;

		ion-icon {
			margin-right: 4px;
		}
	}

	&.invalid {
		--background: darkgrey;
		color: grey;
	}
	&.white {
		--background: white;
		color: var(--ion-color-primary);
		--background-activated: #e5ebea;
		--background-hover: #e5ebea;
		--background-focused: #e5ebea;
		--riple-color: white;
	}
	&.content-only {
		width: auto;
		height: auto;
		border: 0;
		margin: 0;
		padding: 0 5px;
		--padding-start: 0;
		--padding-end: 0;
		--box-shadow: none;
		--background: none;

		ion-icon {
			margin-right: 0;
		}
	}
}

.bold {
	font-weight: bold;
}

.menu-row ion-icon .icon-inner svg path {
	fill: red !important;
}

.product-info {
	.action-sheet-group {
		height: 300px;
		padding: 32px 30px !important;

		.action-sheet-title {
			font-family: AppFont-bold, sans-serif;
			color: #333333;
			font-size: 26px;
			padding: 0;

			.action-sheet-sub-title {
				font-family: AppFont, sans-serif;
				font-size: 13px;
				line-height: 16px;
			}
		}
	}
}

.overflow-auto {
	overflow: auto;
}

// ion-fab-button {
//   --background: transparent !important;
// }

.hours-modal {
	@include desktop {
		ion-backdrop {
			background: #000000 !important;
		}
	}
}

.modal-wrapper {
	min-height: 85vh !important;

	> .modal-wrapper {
		max-width: 450px;
	}
}

.product-modal {
	.modal-wrapper {
		@include desktop {
			width: 450px !important;
			height: auto !important;
		}
	}
}

.hours-modal .modal-wrapper {
	height: calc(100% - 65px);
	bottom: 0;
	position: absolute;
	display: block;

	@include desktop {
		height: 600px;
		width: 480px;
		position: unset;
		bottom: unset;
	}
}

.short-info-modal {
	background: rgba(0, 0, 0, 0.2);
}

.short-info-modal > .modal-wrapper {
	border-radius: 5px;
	width: 95vw;
	@include desktop {
		width: 350px;
	}
}

.map-location-modal {
	--width: 90%;
}

.item-interactive.ion-valid {
	--highlight-background: var(--ion-color-black);
}

.ion-color-white {
	color: #fff;
}

.google-search-input {
	input {
		font-size: 15px !important;
		padding: 20px 0 !important;
	}
}

.menu-popover {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

	.popover-content {
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
		@include desktop {
			width: 315px;
			height: auto;
			min-height: auto;
			top: 75px !important;
			left: unset !important;
			right: 12px !important;
		}
	}

	.popover-arrow {
		right: 20px !important;

		&:after {
			background: #fff !important;
			border-radius: 0 !important;
		}
	}
}

.home-popover {
	.popover-content {
		width: 285px;
		border-radius: 0;
		left: 50px !important;
		top: 58px !important;
		@include desktop {
			top: 68px !important;
			left: 0 !important;
			right: 0 !important;
			margin: 0 auto;
		}
		@media screen and (max-width: 320px) {
			width: 250px;
		}
	}

	.popover-arrow {
		left: 70px !important;
		top: 50px !important;

		@include desktop {
			left: -220px !important;
			top: 60px !important;
			right: 0;
			margin: auto;
		}

		&:after {
			background: #fff !important;
			border-radius: 0 !important;
			width: 10px !important;
			height: 10px !important;
		}
	}
}

.step-heading {
	font-family: AppFont-black, sans-serif;
	font-size: 28px;
	text-align: left;
	margin: 10px 0 20px;
}

.small-padding {
	.step-heading {
		font-size: 18px;
		margin: 0 0 25px;
	}
}

.position-relative {
	position: relative !important;
}

.height-auto {
	height: auto !important;
}

.pac-container {
	background: white;
	width: 100%;
	border-radius: 5px;
	padding: 8px 20px !important;
	margin-top: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
	border: none !important;

	.pac-item {
		border-top: none !important;
		font-family: AppFont, sans-serif;
		padding: 16px 0;
		line-height: 1 !important;
		font-size: 14px !important;
		border-bottom: 0.5px solid #e8e8e8;

		&:last-child {
			border-bottom: none;
		}

		.pac-icon {
			display: none !important;
		}

		.pac-matched {
			font-family: AppFont-bold, sans-serif;
		}
	}
}

.preorder-type {
	background: var(--ion-color-secondary);
	font-size: x-small;
	height: auto;
	margin: 0 0 0 10px;
	padding: 2px 4px;
	color: #fff;
	border-radius: 4px;
}

ion-radio {
	margin: 10px;
	--border-color: var(--radio-color);
	--border-color-checked: var(--ion-color-primary);
	--background-checked: var(--radio-color);
	--color-checked: var(--ion-color-primary);
	--color: var(--radio-color);
}

ion-checkbox {
	margin: 10px;
	--border-color: var(--checkbox-color);
	--border-color-checked: var(--ion-color-primary);
	--background: transparent;
	--color-checked: var(--checkbox-color);
	--color: var(--checkbox-color);
}

.pointer {
	cursor: pointer !important;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
	input {
		cursor: pointer !important;
	}
}

ion-row.center {
	justify-content: center;
	height: 100%;
	align-items: center;
}

.cdk-overlay-pane {
	bottom: 0;
}

app-order {
	background: var(--background-color);
	background-size: 100% 30vh !important;

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}

.display-contents {
	display: contents;
}

.display-block {
	display: block;
}

.display-grid {
	display: grid;
}

.label-floating {
	z-index: 4;
}

app-burger-btn {
	cursor: pointer;
}

.underline {
	color: var(--ion-color-primary);
	text-decoration: underline;
}

ion-picker {
	touch-action: none;
}

.cc-animate {
	transition: transform 0.3s ease-in-out !important;
}

.cc-revoke {
	transform: translateY(100%) !important;
}

.cc-active.cc-revoke {
	transform: translateY(0) !important;
}

@include desktop {
	ion-picker-column {
		overflow: auto;
	}
	.picker-opts {
		top: unset !important;
		transform: none !important;
		pointer-events: none;
	}
	.picker-opt {
		position: relative !important;
		top: unset !important;
		left: unset !important;
		transform: none !important;
	}
	.picker-opt-selected {
		transition: 100ms;
		font-size: 30px;
		font-weight: bold;
	}
}

.flex-grow {
	display: flex;
	flex-grow: 1;
}

.align-center {
	text-align: center;
}
ion-searchbar ion-icon {
	left: 6px !important;
	right: auto !important;
}
.sc-ion-searchbar-md-h {
	padding-inline-start: 11px !important;
}
.searchbar-input.sc-ion-searchbar-md {
	box-shadow: unset !important;
	border: 1px solid #e4eaf0;
	border-radius: 10px !important;
	padding-inline-start: 28px !important;
}

ion-header:after {
	display: none;
}

ion-modal.auto-height {
	background: rgba(0, 0, 0, 0.2);
	--height: auto;

	.modal-wrapper {
		min-height: unset !important;

		.ion-page {
			position: relative;
			contain: content;
			max-height: 95vh;

			.modal-content {
				overflow: auto;
			}
		}
	}
}
ion-modal.eight_height {
	background: rgba(0, 0, 0, 0.2);
	--height: 80vh;
	--width: 400px;
	display: flex;
	justify-content: center;
	align-items: center;

	.modal-wrapper {
		min-height: unset !important;

		.ion-page {
			position: relative;
			contain: content;
			max-height: 95vh;

			.modal-content {
				overflow: auto;
			}
		}
	}
}

ion-modal.align-bottom {
	background: rgba(0, 0, 0, 0.2);

	.modal-wrapper {
		position: absolute;
		bottom: 0;
	}
}

ion-modal.align-bottom-only-mobile {
	background: rgba(0, 0, 0, 0.2);

	.modal-wrapper {
		position: absolute;
		bottom: 0;
		@include desktop {
			bottom: unset !important;
		}
	}
}

ion-modal.allow-gps-modal .modal-wrapper {
	@include mobile {
		width: 95vw;
	}
}

.light-grey-transparent {
	background: rgba(0, 0, 0, 0.2);
	transition: 0.1s linear;
}

app-promo-code {
	display: block;
}

.wpwl-group-button {
	display: flex;
	place-content: center;
	flex-direction: row;
}

.wpwl-wrapper {
	width: 100%;
}

.wpwl-control {
	border: 1px solid rgba(0, 0, 0, 0.4) !important;
	border: none;
	border-radius: 0px;
	padding: 4px 8px;
}

.wpwl-label {
	width: 100%;
	font-size: 13px;
	padding: 4px 10px;
}

.wpwl-wrapper-brand {
	width: 80%;
}

.wpwl-brand-card {
	width: 20%;
}

.wpwl-sup-wrapper-state {
	display: none;
}

.wpwl-sup-wrapper-street2 {
	display: none;
}

.cc_dialog {
	width: 100% !important;
	max-width: 100% !important;
	left: 0;
	right: 0;
	padding: 16px !important;
	background: var(--ion-color-primary) !important;

	p {
		font-size: 10px !important;
	}

	h1 {
		font-size: 16px;
	}
}

.cc_cp_f_powered_by {
	display: none !important;
	content: none !important;
}

.cc_cp_m_content_entry {
	height: auto !important;
	max-height: 500px !important;
	overflow: auto !important;
}

.checkbox_cookie_consent {
	display: none;
}

.cc_cp_f_save {
	button {
		background: var(--ion-color-primary) !important;
	}
}

.cc_b_cp {
	color: var(--ion-color-primary) !important;
	background: white !important;
	border: 2px solid white !important;
	border-radius: 28px !important;
}

.cc_b_ok {
	color: white !important;
	border: 2px solid white !important;
	background-color: var(--ion-color-primary) !important;
	border-radius: 28px !important;
}

app-add-to-home {
	position: absolute;
	bottom: 0;
}

ion-row.center-vertical {
	> * {
		align-self: center;
	}
}

.dashed-border {
	border: 2px dashed var(--ion-color-primary);
}

.tip-picker {
	.picker-columns {
		width: 30%;
		min-width: 200px;
		margin: 0 auto;

		ion-picker-column > .picker-opts > .picker-opt-selected {
			font-size: 25px;
			color: var(--ion-color-primary);
			font-weight: bold;
		}

		.sign-column {
			width: 30px;
		}

		.comma-column {
			width: 30px;
		}
	}
}

.padding-5 {
	padding: 5px;
}

.max-height-100 {
	max-height: 100%;
}

ion-col.center-horizontal > * {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.allow-gps-modal > .modal-wrapper {
	background: transparent url(#{$brandAssets}/allow_gps_modal_background.png) center center no-repeat !important;
	background-size: cover !important;
	--width: 362px !important;
	height: 343px;
	border-radius: 5px;

	> .ion-page {
		background: none;
	}
}

.picker-opt-selected {
	font-weight: bold;
}

ion-modal {
	backdrop-filter: blur(1.5px);
}

.line-break-anywhere {
	line-break: anywhere;
}

.item-modal {
	@include desktop {
		--height: 91vh;
		--width: 450px;
	}
}
.item-modal > .modal-wrapper {
	max-width: 150px;
}

.item-modal-checkout {
	@include desktop {
		--height: 91vh;
		--width: 520px;
	}
}
.item-modal-checkout> .modal-wrapper {
	max-width: 150px;
}
app-payment-modal {
	ion-content {
		ion-row {
			background: white !important;
			margin: 20px !important ;
		}
	}

	.separator {
		height: 14px;
		width: 90% !important;
		margin: 0 auto !important;
		background: url('./assets/icon/separator_doted.svg') no-repeat center center/ 100% 100%;
		@include desktop {
			background: url('./assets/icon/separator_doted.svg') no-repeat center center/ 100% 130%;
			@media (max-height: 750px) {
				height: 11px;
			}
		}
	}
	h1 {
		margin-left: 20px !important;
		font-weight: bold !important;
		font-size: 16px !important;
		line-height: 20px !important;
		color: var(--ion-color-black) !important;
	}
	.payment-input {
		--payment-modal-item-background: white !important;
		border-radius: 0px !important;
		border: 1px solid #ececec !important;
	}
	.wpwl-label {
		color: #54504c;
	}
	iframe {
		background: white;
	}
	ion-item {
		box-sizing: border-box;
		border-radius: 0px !important;
		margin: 0px !important;
		border: none !important;
		ion-label {
			font-family: AppFontBold, sans-serif !important;
			color: var(--ion-color-black) !important;
			font-size: 16px !important;
		}
		ion-icon {
			zoom: 2.4 !important;
		}
	}
	.wpwl-button-pay {
		float: none !important;
		width: 100%;
		background: var(--ion-color-primary);
		border-radius: 28px;
		background-color: var(--ion-color-primary);
		border-color: var(--ion-color-primary);
		font-family: AppFontBold, sans-serif;
		font-size: 24px;
	}
}

ion-searchbar ion-icon {
	left: 6px !important;
	right: auto !important;
}

.sc-ion-searchbar-md-h {
	padding-inline-start: 11px !important;
}

.searchbar-clear-button.sc-ion-searchbar-md {
	display: block !important;
}

.error_container {
	height: 20px;
	position: relative;
	margin-bottom: -5px;
}

.error_container_highter {
	height: 20px;
}

.input-error {
	position: absolute;
	opacity: 0;
	color: rgb(119, 25, 25);

	font-size: 12px;
}

.input_info {
	position: absolute;
	opacity: 0;
	color: rgb(119, 25, 25);
	font-family: AppFontLight;
	font-size: 12px;
	letter-spacing: 0em;
}

.ion-invalid.ion-info {
	--highlight-background: #6b6b6b;
}

.visible {
	opacity: 1;
	transition: opacity 0.3s;
}

.icon-size {
	width: 18px;
}
.gpay-card-info-iframe {
	background: black !important;
}
.cluster {
	display: flex;
	justify-content: center;

	> img {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: 0 auto;
		content: url(/assets/icon/maps_marker_cluster.svg);
		height: 50px;
		width: 50px;
	}

	> div {
		display: contents;
		line-height: 2rem !important;

		> span {
			z-index: 2;
			color: var(--ion-color-black);
			position: absolute;
			top: 1px;
			font-size: 14px;
			font-family: AppFontBold, sans-serif !important;
		}
	}
}

.swiper-pagination {
	left: 20px !important;
}
.cdk-overlay-container {
	position: absolute;
	bottom: 10px;
	width: 80%;
	left: 50%;
	transform: translateX(-50%);

	@include mobile {
		left: 0 !important;
		transform: none !important;
		width: 70%;
	}
	.mdc-snackbar__surface {
		background: var(--ion-color-primary) !important;
		color: white;
		border-radius: 10px;
	}
}

.mat-mdc-snack-bar-container {
	@include mobile {
		margin: 0 !important;
	}
}
.map-location-modal .modal-wrapper {
	@include desktop {
		width: 400px;
	}
}
.map-location-modal {
	--background: transparent !important;
	--width: auto;
	--border-radius: 0px !important;
	--height: fit-content;
	@include mobile {
		--max-height: 88vh;
	}
	--box-shadow: none !important;
}
.map-location-modal .ion-page {
	border-radius: 0px;
	width: 95vw;

	display: flex;
	align-items: center;
	justify-content: center;
	@include desktop {
		width: 400px;
	}
}

.dialog-primary-background {
	--background: transparent !important;
	--width: auto;
	--border-radius: 0px !important;
	--max-height: 70vh;
	@include mobile {
		--max-height: 88vh;
	}
	--box-shadow: none !important;
}

.dialog-primary-background .ion-page {
	border-radius: 0px;
	width: 95vw;

	display: flex;
	align-items: center;
	justify-content: center;
	@include desktop {
		width: 350px;
	}
	background: url('./assets/background/background.png') no-repeat center center/ 100% 100%;
}
.popover-home {
	--offset-x: 12%;
	--offset-y: -20%;
}
.menu-modal {
	@include desktop {
		--height: fit-content !important;
	}
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: #000;
	-webkit-box-shadow: 0 0 0px 40rem #ffffff inset;
}

.working-hours-modal {
	--max-width: 450px;
	@include desktop {
		--width: 375px;
		--height: 90%;
		--max-height: 800px;
	}
	
	.modal-wrapper {
		@include desktop {
		}
	}
}
.footer_privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--ion-color-primary);

    z-index: 999;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 30px;
    p {
        font-size: 12px;
        color: white;
        cursor: pointer;
    }
	.line_vertical {
		width: 1px;
		height: 15px;
		background: white;
		margin: 0px 10px;
	}
}

.map-toggle-container {
	ion-icon:nth-child(2) {
		@include desktop {
			display: none;
		}
	}
}